import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../Theming/Theming';
import subsFromParentQuery from './SubcategoriesFromParentQuery.gql';
import { useQuery } from 'react-apollo';
import { ScrollBox } from '../ui/ScrollBox';

const SubCategoriesWrapper = styled.div`
  padding: 0.7rem 0;
  ul.subcategories-list {
    display: flex;
    margin: 0 -0.5rem 0;
    > li {
      margin: 0 0.5rem;
    }
  }
`;

const SubCategory = styled.li`
  border-bottom: 1px solid ${theme.colors.white};
  &.selected {
    border-color: ${theme.colors.primaryDark};
  }
  a {
    display: block;
  }
`;

const NameTag = styled('h4')`
  font-weight: ${theme.categoryPage.subcategories.fontWeight} !important;
  font-family: ${theme.categoryPage.subcategories.fontFamily} !important;
`;

export const SubCategories = ({ category, parents }) => {
  //PARENT CATEGORY PATH
  const parentPath = (parents && parents.length > 0 && parents[0].path) || '/';

  //FETCHING SIBLING CATEGORIES
  const siblingResult = useQuery(subsFromParentQuery, {
    variables: { path: parentPath },
    skip: category.hasSubcategories
  });

  //SHOW SUBCATEGORIES OR SIBLINGCATEGORIES
  const subcategories = category.hasSubcategories
    ? category.subcategories
    : siblingResult?.data?.route?.object?.subcategories;

  if (subcategories && subcategories.length > 0)
    return (
      <SubCategoriesWrapper className="subcategories-wrapper">
        <ScrollBox>
          <ul className="subcategories-list">
            {subcategories.map(item => (
              <SubCategory
                key={item.id}
                className={category.id === item.id ? 'selected' : ''}
              >
                <CategoryLink category={item}>
                  <NameTag>{item.name}</NameTag>
                </CategoryLink>
              </SubCategory>
            ))}
          </ul>
        </ScrollBox>
      </SubCategoriesWrapper>
    );
  else return null;
};
