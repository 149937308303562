import React, { Component } from 'react';
import { styled } from 'linaria/react';
import PlusIcon from '../ui/icons/PlusIcon';
import MinusIcon from '../ui/icons/MinusIcon';
import t from '@jetshop/intl';

const ReadMoreWrapper = styled('div')``;

const Content = styled('div')`
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s;
  &.read-more-active {
    max-height: ${props => props.maxHeight + 'px'};
  }
  &.read-more-active:not(.read-more-expanded) {
    &:after {
      position: absolute;
      bottom: 0;
      content: ' ';
      display: block;
      height: 30px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      width: 100%;
    }
  }
`;

const ReadMoreButton = styled('button')`
  padding: 0.5rem 0;
  width: 100%;
  svg {
    font-size: 1rem;
  }
`;

class ReadMore extends Component {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
    this.state = {
      height: this.props.breakPoint,
      expanded: false
    };
  }
  componentDidMount() {
    const height = this.contentRef.current.clientHeight;
    this.setState({ height });
  }
  active = () => {
    return this.state.height >= this.props.breakPoint ? true : false;
  };
  handleClick = () => {
    if (this.state.expanded) this.setState({ expanded: false });
    else this.setState({ expanded: true });
  };
  getClassName = () => {
    let classNames = '';
    if (this.active()) classNames += 'read-more-active ';
    if (this.state.expanded) classNames += 'read-more-expanded';
    return classNames;
  };
  render() {
    const { icon } = this.props;
    return (
      <ReadMoreWrapper>
        <Content
          className={this.getClassName()}
          style={
            this.state.expanded ? { maxHeight: this.state.height + 50 } : {}
          }
          maxHeight={this.props.maxHeight}
        >
          <div className="inner-content" ref={this.contentRef}>
            {this.props.children}
          </div>
        </Content>
        {this.active() && (
          <ReadMoreButton
            onClick={this.handleClick}
            className="read-more-button"
          >
            {icon ? (
              <>{this.state.expanded ? <MinusIcon /> : <PlusIcon />} </>
            ) : (
              <>{this.state.expanded ? t('Hide') : t('Read more')}</>
            )}
          </ReadMoreButton>
        )}
      </ReadMoreWrapper>
    );
  }
}

ReadMore.defaultProps = {
  breakPoint: 200,
  maxHeight: 70
};

export default ReadMore;
