import React from 'react';
import { useQuery } from 'react-apollo';
import userContentQuery from './userContentQuery.gql';
import catContentQuery from './categoryContentQuery.gql';
import { theme } from '../../Theming/Theming';
import { ContentRender } from '../../ContentRender/ContentRender';
import { parseJsonRaw } from '../../Util/ParseJson';

const CustomContent = () => {
  const userResult = useQuery(userContentQuery);
  const catResult = useQuery(catContentQuery, {
    variables: { id: theme.config.routes.customCategory?.fetchId }
  });

  const customerAccessCode = parseJsonRaw(
    userResult.data?.customer?.dynamicContent
  ).data?.accessCode;

  const category = catResult?.data?.category?.subcategories?.filter(
    cat => cat.name === customerAccessCode
  )[0];

  if (!category) return null;

  return <ContentRender data={category.data} />;
};

export default CustomContent;
