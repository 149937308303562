const ParseJson = function(str) {
  try {
    return { content: JSON.parse(str.replace(/\r?\n|\r/g, '')), isJson: true };
  } catch (e) {
    return { content: str, isJson: false };
  }
};

export const parseJsonRaw = function(str) {
  try {
    return { data: JSON.parse(str), isJson: true };
  } catch (e) {
    return { data: str, isJson: false };
  }
};

export default ParseJson;
