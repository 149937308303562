import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image/Image';
import ReadMore from '../Util/ReadMore';
import { SubCategories } from './SubCategories';
import { BreadcrumbsWrapper } from '../Layout/BreadcrumbsWrapper';
import { theme } from '../Theming/Theming';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { ContentRender } from '../ContentRender/ContentRender';
import CustomContent from './CustomContent/CustomContent';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

const CategoryImageWrapper = styled('div')`
  ${theme.below.md} {
    [data-flight-image-container] {
      padding-bottom: 25% !important;
    }
  }
`;

const CategoryName = styled('h1')`
  position: relative;
  display: flex;
  align-items: center;
`;

const CategoryTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .breadcrumbs-wrapper {
    padding: 0.7rem 0 0.5rem;
    margin: 0;
  }
  &.standard {
    .inner-content > div > *:first-child {
      margin-top: 0;
    }
    .breadcrumbs-wrapper ul {
      justify-content: center;
    }
    ${CategoryName} {
      justify-content: center;
      .back-arrow {
        position: absolute;
        left: 0;
      }
      &.only-name {
        text-align: left;
      }
      ${theme.only.lg} {
        font-size: ${theme.typography.header.fontSize[2][2]};
      }
      ${theme.above.lg} {
        //margin-bottom: 0.5em;
      }
    }
    ${theme.above.lg} {
      padding: 0 1rem;
    }
    .read-more-button {
      ${theme.below.lg} {
        padding: 0;
      }
    }
    .subcategories-list {
      margin: 0 auto;
    }
    .category-description,
    .category-name {
      width: 100%;
      margin: 0 auto;
      max-width: 800px;
    }
  }
  &:not(.standard) {
    margin: 0 auto 4rem;
    max-width: ${theme.general.maxWidthText};
  }
  padding: 0 ${theme.general.pagePadding.sm};
`;

const CategoryDescription = styled('div')`
  padding-bottom: 0.5rem;
`;

const CategoryHeaderWrapper = styled('header')`
  &.standard {
    ${CategoryDescription} {
      text-align: center;
    }
  }
  ${theme.below.md} {
    margin-bottom: 1rem;
  }
  ${theme.above.lg} {
    &:not(.has-image) {
      margin-bottom: 1rem;
      &.standard {
        ${CategoryTextWrapper} {
          text-align: center;
        }
      }
    }
    &.standard.has-image {
      display: flex;
      ${CategoryImageWrapper} {
        order: 1;
        width: 50%;
      }
      ${CategoryTextWrapper} {
        width: 50%;
        ${theme.above.lg} {
          padding: 0 3rem;
        }
        .breadcrumbs-wrapper {
          ${theme.above.lg} {
            padding-top: 0;
          }
        }
      }
    }
  }
`;

const BackArrowWrapper = styled.div`
  display: inline-block;
  margin-right: 1rem;
  a {
    display: flex;
  }
  svg {
    font-size: 2rem;
  }
`;

export const BackArrow = ({ parents }) => {
  const parentPath = (parents && parents.length > 0 && parents[0].path) || '/';
  return (
    <BackArrowWrapper className="back-arrow">
      <Link to={parentPath}>
        <FontAwesomeIcon icon={faLongArrowLeft} />
      </Link>
    </BackArrowWrapper>
  );
};

const CategoryHeader = ({
  category,
  content = category.data,
  standardCategory = true,
  breadcrumbProps = false,
  showSubs = true
}) => {
  const hasImage = category.images && category.images.length > 0;
  const customContent = theme.config.routes.customCategory?.id === category.id;
  const { loggedIn } = useAuth();
  const showStandardContent = !customContent || (customContent && !loggedIn);

  return (
    <>
      <CategoryHeaderWrapper
        className={cx(standardCategory && 'standard', hasImage && 'has-image')}
      >
        {hasImage ? (
          <CategoryImageWrapper className={`category-image-wrapper`}>
            <Image
              critical={true}
              aspect={standardCategory ? '30:15' : '30:7'}
              crop={true}
              src={category.images[4].url}
              sizes={[1, 1, 1, standardCategory ? 1 / 2 : 1200]}
              cover
              quality={80}
            />
          </CategoryImageWrapper>
        ) : null}
        <CategoryTextWrapper
          className={`category-text-wrapper ${
            standardCategory ? 'standard' : ''
          }`}
        >
          <BreadcrumbsWrapper {...breadcrumbProps} />
          <CategoryName data-testid="page-header" className="category-name">
            <BackArrow parents={breadcrumbProps.parents} />
            {category.name}
          </CategoryName>
          {category.content && (
            <CategoryDescription
              className={`category-description content-area ${
                standardCategory ? 'standard' : ''
              }`}
              data-testid="category-description"
            >
              {standardCategory ? (
                <ReadMore breakPoint={150} maxHeight={100} icon={true}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: category.content
                    }}
                  />
                </ReadMore>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: category.content
                  }}
                />
              )}
            </CategoryDescription>
          )}
          {showSubs && (
            <SubCategories
              category={category}
              parents={breadcrumbProps.parents}
            />
          )}
        </CategoryTextWrapper>
      </CategoryHeaderWrapper>
      {showStandardContent && <ContentRender data={content} />}
      {customContent && <CustomContent />}
    </>
  );
};

export default CategoryHeader;
